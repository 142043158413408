<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                  label="Halkategória"
              >
                <validation-provider
                    #default="{ errors }"
                    name="halkategória"
                    rules="required"
                    vid="fishCategory"
                >
                  <v-select
                      v-model="formData.fishCategory"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="fishCategoryOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Név"
              >
                <validation-provider
                    #default="{ errors }"
                    name="név"
                    rules="required"
                    vid="name"
                >
                  <b-form-input
                      v-model="formData.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="név"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Szín"
              >
                <validation-provider
                    #default="{ errors }"
                    name="szín"
                    rules="required"
                    vid="color"
                >
                  <b-form-input
                      type="color"
                      v-model="formData.color"
                      :state="errors.length > 0 ? false:null"
                      placeholder="szín"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_fishType/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'record_catch',
      formData: {
        id: null,
        name: '',
        color: '',
        fishCategory: ''
      },
      fishCategoryOptions: [],
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchFishType', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id

      this.$store.dispatch('fetchFishCategoriesForSelect').then(fcResponse => {
        this.fishCategoryOptions = fcResponse

        if (this.fishCategoryOptions.length) {
          Object.keys(fcResponse).forEach(key => {
            if (fcResponse[key].value === parseInt(response.data.entity.fish_category_id)) {
              this.formData.fishCategory = fcResponse[key]
            }
          })
        }
      })
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = {...this.formData}
          fd.fishCategory = this.formData.fishCategory.value

          this.$store.dispatch('updateFishType', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'fish-types'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
